<template>
   <Sidebar v-on:goto="goto($event)" :mobile="mobile_active"></Sidebar>
   <i class="bi mobile-nav-toggle" :class="korv" @click="mobile_active = !mobile_active"></i>

   <div class="sections">
      <section id="intro" ref="intro" class="intro">
         <Intro></Intro>
      </section>

      <section id="skills" ref="skills" class="skills">
         <Skills></Skills>
      </section>

      <section id="resume" ref="resume" class="resume">
         <Resume></Resume>
      </section>

      <section id="education" ref="education" class="resume">
         <Education></Education>
      </section>

      <section id="contact" ref="contact" class="contact">
         <Contact></Contact>
      </section>
   </div>

</template>

<script>
   import Sidebar from '../components/views/Sidebar';
   import Education from '../components/views/Education';
   import Intro from '../components/views/Intro';
   import Resume from '../components/views/Resume';
   import Skills from '../components/views/Skills';
   import Contact from '../components/views/Contact';

   export default {
      name: 'Home',
      components: {
         Sidebar,
         Education,
         Intro,
         Resume,
         Skills,
         Contact
      },

      data: function () {
         return {
            mobile_active: false,
         }
      },

      created: function () {
      },

      mounted: function () {
      },

      unmounted() {
      },

      computed: {
         korv: function () {
            return this.mobile_active ? 'bi-x' : 'bi-list';
         }
      },

      methods: {
         goto: function (refName) {
            if (this.mobile_active) {
               this.mobile_active = false;
            }
            window.scrollTo(0, this.$refs[refName].offsetTop);
         },
      },
   }
</script>

<style lang="scss">
   @import "./styles/piniesjo_style.scss";

   .sections {
      margin-left: 400px;
      margin-right: 40px;
   }

   @media (max-width: 768px) {
      .sections {
         margin-left: 1.5em;
      }
   }

   @media (min-width: 769px) {
      .mobile-nav-toggle {
         display: none !important;
      }
   }

   .mobile-nav-toggle {
     position: fixed;
     right: 15px;
     top: 15px;
     z-index: 9998;
     border: 0;
     font-size: 24px;
     transition: all 0.4s;
     outline: none !important;
     background-color: #e00;
     color: #fff;
     width: 40px;
     height: 40px;
     display: inline-flex;
     align-items: center;
     justify-content: center;
     line-height: 0;
     border-radius: 50px;
     cursor: pointer;
   }

</style>
