<template>
   <div class="sidebar container-fluid" v-bind:class="{ mobile_active : mobile }">
      <div class="row">
         <div class="col-md-12 text-center">
            <img src="@/assets/anders_rect.jpg" class="side-image img-responsive rounded-circle center">
         </div>
      </div>

      <div class="row">
         <div class="col-md-12 text-center">
            <h3>Anders Piniesjö AB</h3>
         </div>
      </div>

      <div class="row">
         <div class="col-md-12 contacts text-center">
            <a href="https://www.facebook.com/anderspiniesjoe" class="bi-facebook" style="margin-right: 1em;"></a>
            <a href="https://www.linkedin.com/in/anderspiniesjoe/" class="bi-linkedin"></a>
         </div>
      </div>

      <div class="row mt-5">
         <div class="col-md-12">
            <hr/>
         </div>
      </div>

      <div class="row">
         <div class="col-md-12 section-links">
            <ul>
               <li><a @click="$emit('goto', 'intro')">Om</a></li>
               <li><a @click="$emit('goto', 'skills')">Kunskaper</a></li>
               <li><a @click="$emit('goto', 'resume')">Anställningar och uppdrag</a></li>
               <li><a @click="$emit('goto', 'education')">Erfarenheter</a></li>
               <li><a @click="$emit('goto', 'contact')">Kontakt</a></li>
            </ul>
         </div>
      </div>

      <div class="row">
         <div class="col-md-12">
            <hr/>
         </div>
      </div>
   </div>
</template>

<script>
   import 'bootstrap-icons/font/bootstrap-icons.css'

   export default {
      name: 'Sidebar',
      props: ['mobile'],
   }
</script>

<style scoped lang="scss">
@import "../../../styles/piniesjo_variables";


.sidebar {
   position: fixed;
   top: 0; left: 0;
   width: 350px;
   height: 100%;
   background-color: #d00;
   font-family: Korv;
   font-weight: bold;
   z-index: 1000;

   hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid white;
      opacity: 1.0;
      margin: 1em 1.2em;
      padding: 0;
   }

   h3 {
      line-height: 24px;
      font-size: 24px;
      font-weight: 800;
      font-family: Korv;
      color: white;
      margin-bottom: 0em;
   }

   h4 {
      line-height: 18px;
      font-size: 18px;
      font-weight: 600;
      font-family: Korv;
      color: $title;
      margin-bottom: 10px;
   }

   .side-image-back {
      margin: 2em 1em 2em 1em;
      width: 200px; height: 200px;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.5;
   }

   .side-image {
      margin: 2em 1em 2em 1em;
      width: 200px; height: 200px;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 1.0;
   }

   .contacts {
      margin-top: 1em;
      .dot {
         height: 2em;
         width: 2em;
         background-color: white;
         border-radius: 50%;
         display: inline-block;
      }

      a {
         color: white;
         font-size: 1.5em;
      }
   }

   .section-links {
      font-size: 1.5em;
      margin-top: 0em;
      margin-bottom: 0em;
      margin-left: 1.1em;
      color: white;

      ul {
         list-style: none;
         padding: 0;
         margin-bottom: 0;
         line-height: 90%;

         li {
            margin-bottom: 1em;
         }

         li:last-child {
            margin-bottom: 0;
         }
      }

      a {
         //display: flex;
         //align-items: left;
         cursor: pointer;
         color: white;
         font-size: 1em;
         text-decoration: none;
         font-weight: 550;
      }
   }
}

@media (max-width: 768px) {
   .sidebar {
      left: -350px;
      transition: left 0.5s;
   }
}

.mobile_active {
   overflow: hidden;
   left: 0;
   transition: left 0.5s;
}

.mobile_active .sidebar {
}

</style>
