<template>
   <div class="container-md">
      <div class="section-title">
         <h2>Om</h2>
      </div>

      <div class="row">
         <div class="col-sm-12 col-xl-4 mb-xl-0 mb-sm-3">
            <img src="@/assets/anders.jpg" style="max-width: 100%; max-height: 100%;">
         </div>

         <div class="col-sm-12 col-xl-8 mt-4 mt-xl-0">
            <div class="row">
               <div class="col-sm-12 col-xl-6 content">
                  <h3>Drivkraft</h3>
                  <p>
                     Att vara med när system och mjukvara designas, implementeras, testas, driftsätts och levereras är en fantastiskt kreativ upplevelse.
                     Jag har under min karriär varit med om många sådana cykler och jag lär mig fortfarande nya saker varje gång. Det allra bästa är när
                     detta görs tillsammans med ett team där mina kunskaper kan sporra och hjälpa andra framåt, samtidigt som jag sporras och
                     hjälps framåt av andras.
                  </p>

                  <p>
                     Så gott som varje projekt innebär nya utmaningar och saker att lära sig. Det gör att yrket aldrig blir tråkigt och det är fantastiskt
                     att utvecklas tillsammans med andra. Att aldrig bli färdiglärd, att lära sig nya tekniker och få fler verktyg är fantastiskt!
                     Detta är min största drivkraft som utvecklare.
                  </p>

                  <h3>Erfarenhet</h3>
                  <p>
                     Efter tjugo år i yrket och ett liv med datorer och programmering så har jag fått en både djup och bred kunskap. Jag kan se,
                     förstå och designa system, men också implementera dem. Jag kan utveckla hela vägen från interface, klient och frontend, ned
                     till server- och backend. Jag förstår och tycker om det inbyggda systemets begränsningar och utmaningar, likväl som det distribuerade
                     molnsystemets kraft. Lång erfarenhet har gett mig en hög produktionstakt, samtidigt som jag kan skapa säkra, stabila och väl
                     fungerande system, med stabil och säker kod.
                  </p>
               </div>

               <div class="col-md-12 col-xl-6 content">
                  <h3>Metoder</h3>
                  <p>
                     Jag trivs mycket bra med att utveckla enligt agila metoder. Jag gillar inkrementell utveckling, med rätt förväntan på systemet
                     eller mjukvaran i varje punkt, med en snabb och tydlig process för utveckling, byggsteg, unit- och integrationstestning,
                     distribution och feedback.
                  </p>

                  <h3>Personlighet</h3>
                  <p>
                     Återkopplingen jag fått från mina tidigare anställningar och uppdrag är att jag har en hjälpsam inställning och ger positiv
                     energi till min omgivning. Att mina kunskaper och förmåga att tänka ut lösningar ger en positiv kraft till teamet och
                     projekten. Att mitt fokus och lugn i stressade situationer hjälper teamet att tillsammans ta projektet framåt också när det
                     är svårt.
                  </p>

                  <p>
                     Utifrån mitt perspektiv har jag lätt för att kommunicera, samarbeta och bygga nya positiva relationer. Jag är positiv och
                     öppen, lätt att lära känna och bra på att bygga relationer till människor i min omgivning. Som kollega är jag en social,
                     trygg, noggrann och positiv kraft.
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
  name: 'Intro',
}
</script>

<style scoped lang="scss">
@import "../../../styles/piniesjo_variables";

.intro .content p {
   font-size: 100%;
}
</style>
