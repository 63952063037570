<template>
   <div class="container-md skills">
      <div class="section-title">
         <h2>Erfarenheter</h2>
      </div>

      <div class="row">
         <div class="col-lg-6">
            <div class="section">
               <h4>Utbildningar</h4>
               <div class="resume-item">
                  <h5>2005 - 2007</h5>
                  <p>Datavetenskaplig magisterutbildning, 240 hp. Examen.<br/>
                     <em>Exjobb: Multithreaded design for faster web browsers.</em></p>
               </div>

               <div class="resume-item">
                  <h5>1997 - 1999</h5>
                  <p>Datavetenskaplig magisterutbildning, 240 hp.<br/>(avbruten för arbete)</p>
               </div>

               <div class="resume-item">
                  <h5>1995 - 1997</h5>
                  <p>Civilingenjör Datateknik, 270 hp.<br/>
                     Senare byte till Datavetenskaplig magisterutbildning.</p>
               </div>

               <div class="resume-item">
                  <h5>1990 - 1993</h5>
                  <p>Tekniskt gymnasieprogram</p>
               </div>
            </div>

            <div class="section">
               <h4>Kurser</h4>
               <div class="resume-item">
                  <h5>2011</h5>
                  <p>Applikationsutveckling för Android, 7.5 hp, Mittuniversitetet</p>
               </div>

               <div class="resume-item">
                  <h5>2011</h5>
                  <p>Programmering av mikrokontroller, 7.5 hp, Luleå Tekniska Universitet</p>
               </div>

               <div class="resume-item">
                  <h5>2003 - 2004</h5>
                  <p>Tyska, nybörjarkurs, 30 hp (halvfart), Linköpings Universitet</p>
               </div>
            </div>
         </div>

         <div class="col-lg-6">
            <div class="section">
               <h4>Språk</h4>
               <div class="resume-item">
                  <h5>Svenska</h5>
                  <p>Modersmål</p>
               </div>

               <div class="resume-item">
                  <h5>Engelska</h5>
                  <p>Flytande i tal och skrift</p>
               </div>

               <div class="resume-item">
                  <h5>Tyska</h5>
                  <p>Självständig språkanvändare</p>
               </div>
            </div>

            <div class="section">
               <h4>Engagemang och meriter</h4>
               <div class="resume-item">
                  Styrelsemedlem i bostadsrättsförening (Eternellen 50), 2015-2018.
               </div>

               <div class="resume-item">
                  Styrelsemedlem i musikförening (Nuclear Nation), 2003-2008.
               </div>

               <div class="resume-item">
                  Styrelsemedlem i datorföreningen Lysator, 1998-2000.
               </div>
            </div>

            <div class="section">
               <h4>Övrigt</h4>
               <div class="resume-item">
                  B-körkort
               </div>
            </div>

         </div>
      </div>
   </div>
</template>

<script>
export default {
  name: 'Education',
}
</script>

<style scoped lang="scss">
@import "../../../styles/piniesjo_variables";

.skills {
   .section {
      margin-bottom: 2em;
   }

   h4 {
      color: #000;
      margin-bottom: 10px;
      margin-left: -10px;
   }
}



</style>
