<template>
<div class="row skill">
   <div class="col-8 name">{{ name }}</div> <div class="col-4 dots">{{ dots }}</div>
</div>
</template>

<script>
export default {
   name: 'Skill',
   props: {
      name: { type: String },
      value: { type: Number },
   },

   computed: {
      dots: function () {
         return '⬤'.repeat(this.value) + '◯'.repeat(5 - this.value);
      }
   }
}
</script>

