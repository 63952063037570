<template>
   <div class="container-md skills">
      <div class="section-title">
         <h2>Kontakt</h2>
      </div>


      <div class="row">
         <div class="col-6 col-sm-4">
               <div class="row">
                  <div class="col-12 mb-4">
                     <h5>Online</h5>
                     <div class="row">
                        <div class="col-md-12 contacts">
                           <a href="https://www.facebook.com/anderspiniesjoe" class="bi-facebook" style="margin-right: 1em;"></a>
                           <a href="https://www.linkedin.com/in/anderspiniesjoe/" class="bi-linkedin"></a>
                        </div>
                     </div>
                  </div>

                  <div class="col-12">
                     <h5>Telefon</h5>
                     <div class="address">
                        0733 - 580 340
                     </div>
                  </div>

               </div>
         </div>

         <div class="col-6 col-sm-8">
            <div class="row">
               <div class="col-12 col-lg-6">
                  <h5>E-post</h5>
                  <div class="address">
                     kontakt@piniesjo.se
                  </div>
               </div>

               <div class="col-12 col-lg-6 mt-4 mt-lg-0">
                  <h5>Adress</h5>
                  <div class="address">
                     Anders Piniesjö AB<br/>
                     Gällstadsvägen 8<br/>
                     585 64  LINGHEM
                  </div>
               </div>
            </div>
         </div>

      </div>
   </div>
</template>

<script>
export default {
  name: 'Contact',
}
</script>

<style scoped lang="scss">
@import "../../../styles/piniesjo_variables";

.contacts {
   font-size: 130%;

   a {
      color: black;
   }
}
</style>
